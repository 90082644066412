import React, { useEffect, useState } from 'react';
import style from './css/payment.module.css';
import axios from 'axios';
import { BASE_URL, TransactionHistoryInterface,UserDataInterface } from './Data';
import coinImage from "./media/coin.png";
import bronzeMedalImage from './media/bronze.png';

interface PaymentProps {

};
const PaymentPage: React.FC<PaymentProps> = () => {
    const [amount, setAmount] = useState<number>(0);
    const [userID, setUserID] = useState<number | null>(null);
    const [userData, setUserData] = useState<UserDataInterface | null>(null)
    const [browserTab, setBrowserTab] = useState<string>("transaction_history");
    const [depositHistory, setDepositHistory] = useState<Array<TransactionHistoryInterface>>([]);
    const [withdrawalHistory, setWithdrawalHistory] = useState<Array<TransactionHistoryInterface>>([]);
    const [referralHistory, setReferralHistory] = useState<Array<TransactionHistoryInterface>>([]);
    const [paymentHistory, setPaymentHistory] = useState<Array<TransactionHistoryInterface>>([]);
    const onFormSubmit = () => {
        if (amount < 30) {
            alert("Minimum deposit amount is 30 MYR");
        } else {
            axios.post(`${BASE_URL}/api/deposit`, {
                data: window?.Telegram?.WebApp.initDataUnsafe,
                amount: amount
            },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            ).then((res) => {

                window?.Telegram?.WebApp.close();

            })
        }
    }
    useEffect(
        () => {
            axios.post(`${BASE_URL}/api/getUser`, {
                "id": window.Telegram?.WebApp.initDataUnsafe.user.id
            }, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }).then(
                (res) => {
                    setUserData(res.data.user);
                    axios.post(`${BASE_URL}/api/readData`, {
                        data: window.Telegram
                    })
                }
            )
        }, []
    )

    useEffect(() => {
        var res = axios.post(
            `${BASE_URL}/api/getPaymentHistory`,
            {
                "user": window.Telegram?.WebApp.initDataUnsafe
            },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            console.log(res.data);
            var data = res.data;
            setDepositHistory(JSON.parse(res.data.deposit));
            setWithdrawalHistory(JSON.parse(res.data.withdrawal));
            setReferralHistory(JSON.parse(res.data.referral_bonus));
            setPaymentHistory(JSON.parse(res.data.payment));
        })
    }, []);

    return (
        <>
            <div className={style.box1}>
                <div className={style.userData}>
                    <img src={window.Telegram?.WebApp.initDataUnsafe.user.photo_url} alt="" className="profile" style={{
                        width: "40px", height: "40px", borderRadius: "6px"
                    }} />
                </div>
                <div className={`${style.sec1}`}>
                    <div className={style.balance}>
                        <p className="t1">Balance</p>
                        <div className="coins">
                            <img src={coinImage} alt="" />
                            <p className="t2">{userData?.balance ? userData.balance.toFixed(2) : "0.00"}</p>
                        </div>
                    </div>
                    <div className={style.league}>
                        <div className={style.levels}>
                            <div className="league-logo">
                                <img src={bronzeMedalImage} alt="" className="logo" />
                            </div>
                            <div className={style.leagueInfo}>
                                <div className="league-info-charter">
                                    <p className="t1">
                                        Bronze
                                    </p>
                                    <p className="t2">
                                        <span>1</span>/7
                                    </p>
                                </div>

                                <div className="progress">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.payment}>

                <h1 className={style.paymentSectionHeader}>
                    We achieved it!
                </h1>
                <div className={style.selectTransactionDataType}>
                    {/* <button onClick={(e) => {
                        e.preventDefault();
                        setBrowserTab("transaction");
                    }}

                        style={browserTab == "transaction" ? {
                            // border:"1px solid white",
                            background: 'var(--Dark-Dark-3, #1E1E1E)',
                            padding: "8px 25px",
                            borderRadius: "5px"
                        } : {}}
                    >Transaction</button> */}
                    <button onClick={(e) => {
                        e.preventDefault();
                        setBrowserTab("transaction_history");
                    }}

                        style={browserTab == "transaction_history" ? {
                            // border:"1px solid white",
                            background: 'var(--Dark-Dark-3, #1E1E1E)',
                            padding: "8px 25px",
                            borderRadius: "5px"
                        } : {}}
                    >History</button>
                </div>


                {
                    browserTab == "transaction" ? (
                        <>
                            <form action="" className={style.paymentForm} onSubmit={(e) => { e.preventDefault(); onFormSubmit() }}>
                                <h1 className={style.paymentHeader}>Add Balance</h1>
                                <input type="number" className={style.payAmount} placeholder='Mini 30'
                                    onChange={(e) => { setAmount(Number(e.target.value)) }} />
                                <button className={style.paymentFormSubmitButton}>
                                    Submit
                                </button>
                            </form>

                        </>
                    ) : (
                        <>



                            <div className={style.depositionType}>
                                <div style={{
                                    display: "flex",
                                    gap: "20px"
                                }}>
                                    <img src="/media/coin.png" alt="" />

                                    <div className={style.totalDeposit}>
                                        <p>Total Deposit</p>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <g clip-path="url(#clip0_537_216)">
                                                    <path d="M17.0184 7.97481C17.1458 8.10211 17.2469 8.25327 17.3158 8.41964C17.3848 8.586 17.4202 8.76433 17.4202 8.94442C17.4202 9.12451 17.3848 9.30283 17.3158 9.4692C17.2469 9.63557 17.1458 9.78672 17.0184 9.91403L9.96187 16.9706C9.70471 17.2276 9.356 17.372 8.9924 17.372C8.62881 17.372 8.2801 17.2276 8.02294 16.9706L0.966371 9.91403C0.709438 9.65692 0.565109 9.30832 0.565109 8.94484C0.565109 8.58136 0.709438 8.23276 0.966371 7.97565L8.02294 0.919091C8.15021 0.791761 8.30132 0.690754 8.46764 0.62184C8.63396 0.552926 8.81223 0.517456 8.99226 0.517456C9.1723 0.517456 9.35056 0.552926 9.51688 0.62184C9.6832 0.690754 9.83432 0.791761 9.96159 0.919091L17.0182 7.97565" fill="#CDCDCD" />
                                                    <path d="M12.1452 5.94519C13.9322 7.70457 13.932 10.5866 12.1448 12.3459V12.3459C10.3969 14.0664 7.59187 14.0665 5.84399 12.3459V12.3459C4.05676 10.5867 4.05676 7.70448 5.84392 5.94512V5.94512C7.59189 4.22433 10.3973 4.22432 12.1452 5.94519V5.94519Z" fill="#2CA3B6" />
                                                    <path d="M9.90955 8.21623C10.4284 8.72715 10.4282 9.56402 9.90913 10.0747V10.0747C9.40169 10.5739 8.58765 10.5739 8.08025 10.0746V10.0746C7.56126 9.56397 7.56106 8.7272 8.07982 8.21629V8.21629C8.58732 7.71648 9.40202 7.71645 9.90955 8.21623V8.21623Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_537_216">
                                                        <rect width="18" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p>{depositHistory.length}</p>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#5F6368" />
                                    </svg>
                                </div>
                            </div>
                            <div className={style.depositionType}>
                                <div style={{
                                    display: "flex",
                                    gap: "20px"
                                }}>
                                    <img src="/media/task.png" alt="" />

                                    <div className={style.totalDeposit}>
                                        <p>Task Earning</p>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <g clip-path="url(#clip0_537_216)">
                                                    <path d="M17.0184 7.97481C17.1458 8.10211 17.2469 8.25327 17.3158 8.41964C17.3848 8.586 17.4202 8.76433 17.4202 8.94442C17.4202 9.12451 17.3848 9.30283 17.3158 9.4692C17.2469 9.63557 17.1458 9.78672 17.0184 9.91403L9.96187 16.9706C9.70471 17.2276 9.356 17.372 8.9924 17.372C8.62881 17.372 8.2801 17.2276 8.02294 16.9706L0.966371 9.91403C0.709438 9.65692 0.565109 9.30832 0.565109 8.94484C0.565109 8.58136 0.709438 8.23276 0.966371 7.97565L8.02294 0.919091C8.15021 0.791761 8.30132 0.690754 8.46764 0.62184C8.63396 0.552926 8.81223 0.517456 8.99226 0.517456C9.1723 0.517456 9.35056 0.552926 9.51688 0.62184C9.6832 0.690754 9.83432 0.791761 9.96159 0.919091L17.0182 7.97565" fill="#CDCDCD" />
                                                    <path d="M12.1452 5.94519C13.9322 7.70457 13.932 10.5866 12.1448 12.3459V12.3459C10.3969 14.0664 7.59187 14.0665 5.84399 12.3459V12.3459C4.05676 10.5867 4.05676 7.70448 5.84392 5.94512V5.94512C7.59189 4.22433 10.3973 4.22432 12.1452 5.94519V5.94519Z" fill="#2CA3B6" />
                                                    <path d="M9.90955 8.21623C10.4284 8.72715 10.4282 9.56402 9.90913 10.0747V10.0747C9.40169 10.5739 8.58765 10.5739 8.08025 10.0746V10.0746C7.56126 9.56397 7.56106 8.7272 8.07982 8.21629V8.21629C8.58732 7.71648 9.40202 7.71645 9.90955 8.21623V8.21623Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_537_216">
                                                        <rect width="18" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#5F6368" />
                                    </svg>
                                </div>
                            </div>

                            <div className={style.depositionType}>
                                <div style={{
                                    display: "flex",
                                    gap: "20px"
                                }}>
                                    <img src="/media/ref.png" alt="" />

                                    <div className={style.totalDeposit}>
                                        <p>Referral Commission</p>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <g clip-path="url(#clip0_537_216)">
                                                    <path d="M17.0184 7.97481C17.1458 8.10211 17.2469 8.25327 17.3158 8.41964C17.3848 8.586 17.4202 8.76433 17.4202 8.94442C17.4202 9.12451 17.3848 9.30283 17.3158 9.4692C17.2469 9.63557 17.1458 9.78672 17.0184 9.91403L9.96187 16.9706C9.70471 17.2276 9.356 17.372 8.9924 17.372C8.62881 17.372 8.2801 17.2276 8.02294 16.9706L0.966371 9.91403C0.709438 9.65692 0.565109 9.30832 0.565109 8.94484C0.565109 8.58136 0.709438 8.23276 0.966371 7.97565L8.02294 0.919091C8.15021 0.791761 8.30132 0.690754 8.46764 0.62184C8.63396 0.552926 8.81223 0.517456 8.99226 0.517456C9.1723 0.517456 9.35056 0.552926 9.51688 0.62184C9.6832 0.690754 9.83432 0.791761 9.96159 0.919091L17.0182 7.97565" fill="#CDCDCD" />
                                                    <path d="M12.1452 5.94519C13.9322 7.70457 13.932 10.5866 12.1448 12.3459V12.3459C10.3969 14.0664 7.59187 14.0665 5.84399 12.3459V12.3459C4.05676 10.5867 4.05676 7.70448 5.84392 5.94512V5.94512C7.59189 4.22433 10.3973 4.22432 12.1452 5.94519V5.94519Z" fill="#2CA3B6" />
                                                    <path d="M9.90955 8.21623C10.4284 8.72715 10.4282 9.56402 9.90913 10.0747V10.0747C9.40169 10.5739 8.58765 10.5739 8.08025 10.0746V10.0746C7.56126 9.56397 7.56106 8.7272 8.07982 8.21629V8.21629C8.58732 7.71648 9.40202 7.71645 9.90955 8.21623V8.21623Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_537_216">
                                                        <rect width="18" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p>{referralHistory.length}</p>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#5F6368" />
                                    </svg>
                                </div>
                            </div>




                            <>
                            
                                {
                                    paymentHistory.length > 0 ? (


                                        <>

                                            <div className={style.paymentHistory}>
                                                <h5 style={{color:"white"}}>Payment History</h5>
                                                {

                                                    paymentHistory.map((payment) => {
                                                        return <>
                                                            <div className={style.historyRow}>
                                                                <p>

                                                                    {payment.fields.date_created.substring(0, 10)}</p>
                                                                <p>{payment.fields.tx_type}</p>
                                                                <p style={payment.fields.tx_type=="withdrawal" ? {color:"crimson"} : {color:"green"}}>
                                                                    {
                                                                        payment.fields.tx_type == "withdrawal" ? (<>-</>) : (<>+</>)
                                                                    } &nbsp;
                                                                    {payment.fields.amount}</p>
                                                            </div>
                                                        </>
                                                    })
                                                }

                                            </div>
                                        </>
                                    ) :
                                        (
                                            <>
                                            </>
                                        )
                                }


                            </>
                        </>
                    )
                }

            </div>

            <div className={style.navMask}>

            </div>

        </>
    );
};

export default PaymentPage;
