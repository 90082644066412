import { useState, useEffect } from "react";
import { motion } from "framer-motion";
// import { Button } from "@/components/ui/button";
import style from "./css/LuckySpin.module.css";
import axios from "axios";
import {BASE_URL} from "./Data"
const prizes = ["8", "5", "6", "LOOSE",];
const segmentAngle = 360 / prizes.length;

export default function LuckySpin() {
    const [angle, setAngle] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const [prize, setPrize] = useState<string | null>(null);
    const [randomSegment_, setRandomSegment] = useState<number>(0);
    const [timerTime , setTimerTime] = useState<Date>();
    const resetWheel = () => {
        setAngle(0); // Reset wheel position
        setPrize(null);
    };
    const convertAngle = (angle: number) => {
        let reducedAngle = angle % 360;
        if (reducedAngle < 0) reducedAngle += 360; // Ensure it's positive
        return reducedAngle;
    }
    const spinWheel = () => {
        if (spinning) return;
        setSpinning(true);

        const randomSegment = Math.floor(Math.random() * prizes.length);
        const randomExtra = randomSegment * segmentAngle//Math.floor(Math.random() * prizes.length) * segmentAngle;
        const newAngle = randomExtra + 360 - convertAngle(Math.abs(angle)) + angle + 2 * 180;
        setAngle(newAngle);
        setTimeout(() => {
            setSpinning(false);
            setPrize(prizes[randomSegment - 1]);
            setRandomSegment(randomSegment)
        }, 4000);
    };


    const [timeLeft, setTimeLeft] = useState(3600);

    useEffect(() => {
        if (timeLeft <= 0) return;
        const interval = setInterval(() => {
            setTimeLeft((prev) => Math.max(prev - 1, 0));
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);



    useEffect(()=>{
        axios.get(`${BASE_URL}/api/getTimer`).then((res)=>{
            const timerTime = new Date(res.data.timerTime);
            setTimerTime(timerTime);

        });

    },[])
    useEffect(()=>{
        if(timerTime){
            const currentTime = new Date(); 
            const differenceInSeconds = Math.floor((timerTime.getTime() - currentTime.getTime()) / 1000);
            setTimeLeft(differenceInSeconds+3*3600);
        }
    },[timerTime])
    const formatTime = (seconds: number) => {
        const h = Math.floor(seconds / 3600)
            .toString()
            .padStart(2, "0");
        const m = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, "0");
        const s = (seconds % 60).toString().padStart(2, "0");
        return `${h}:${m}:${s}`;
    };
    return (
        <div className={style.wheelContainer}>
            <div className={style.ticker}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M6.34302 12.9577L0.659783 0.0248762L12.19 0.0980789L6.34302 12.9577Z" fill="#F1F1F1" />
                </svg>
            </div>
            <div onClick={spinWheel} className={style.spinWheelContainer}>
                <motion.img
                    src="/media/wheel.png"
                    animate={{ rotate: angle }}
                    transition={{ duration: 4, ease: "easeOut" }}
                    className={style.motionImage}
                    style={{
                        position: "relative",
                        top: "0%",
                        left: "12.5%",
                        transform: "translate(-50%, -50%)",
                        width: "80%"
                    }}
                />
            </div>
            <div className={style.timerContainer}>
            <div className={style.timerHeader}>
                <span>Lucky Spin Remaining Time</span>
                <span className={style.timerValue}>{formatTime(timeLeft)}</span>
            </div>
            <div className={style.progressBar}>
                <div
                    className={style.progressFill}
                    style={{ width: `${((timeLeft / 3600)/3) * 100}%` }}
                ></div>
            </div>
        </div>
        </div>
    );
}
