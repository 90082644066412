// export const BASE_URL = "http://127.0.0.1:8000"
export const BASE_URL = "https://slot-lah.net"
export const BOT_NAME = "Slotlah_bot"
export interface TelegramUser {
    id: number; // Unique identifier for the user
    first_name: string; // First name of the user
    last_name?: string; // Optional last name of the user
    username?: string; // Optional username of the user
    language_code?: string; // Optional language code
    allows_write_to_pm?: boolean; // Optional flag if user allows messages from the bot
    photo_url?: string; // Optional profile photo URL
}

export interface TelegramInitData {
    query_id: string; // Query ID from Telegram
    user: TelegramUser; // User information
    auth_date: string; // Authentication date (timestamp)
    signature: string; // Signature for verification
    hash: string; // Hash for verification
}


export interface TelegramWebApp {
    initData: string; // Contains a hash of the user's information
    initDataUnsafe: TelegramInitData;
    // Add other fields or methods from the Telegram WebApp API if needed
    close(): void;
    expand(): void;
    ready():void;
    sendData(data:string): void;
    isExpanded: boolean;
}

export interface UserDataInterface {
    username: string;
    email: string;
    balance: number;
    referralCode: string;
}


export interface TransactionHistoryInterface {
    model: string; // Model name (e.g., "apiapp.transactionhistory")
    pk: number; // Primary key
    fields: TransactionFieldsInterface; // Object containing the fields
}

export interface TransactionFieldsInterface {
    tx_type: "deposit" | "withdrawal" | "referral_bonus"; // Transaction type (use the possible values for strict typing)
    user_id: number; // User ID
    username: string; // Username
    tx_id: string; // Transaction ID
    amount: number; // Transaction amount
    date_created: string; // ISO timestamp
}


export interface referredUserInstance{
    username:string,
    id:number,
    date_joined:string
}
